import { Box, Card, Typography } from "@mui/material";
import { LocalizationContext, SelectedDroner } from "../lib/context";
import { useCallback, useContext, useEffect } from "react";
import CompareSwitchButton from "./CommonComponents/CompareSwitchButton";
import { useRouter } from "next/router";
import ContactButton from "./Profile/ContactButton";
import Link from "next/link";
import { dcContent } from "../../services/content";
import Image from "next/legacy/image";
import ShareIcon from "./Icons/ShareIcon";
import StarIcon from "@mui/icons-material/Star";
import RightBigArrowIcon from "./Icons/RightBigArrowIcon";
import ArrowIcon from "./Icons/ArrowIcon";

export function DronerCard({
  droner,
  searchRes = false,
}: {
  droner: any;
  searchRes?: boolean;
}) {
  const router = useRouter();

  const showEquipment = (arr: any[] | undefined | null) => {
    const fullStr: string = arr?.join(", ") ?? "";
    return fullStr.length > 30 ? fullStr.slice(0, 30) + "..." : fullStr;
  };

  return (
    <Card
      sx={{ maxWidth: "100%", boxShadow: 0 }}
      onClick={() => {
        router.push(`/droners/${droner?.username}`);
      }}
    >
      <Box
        // onClick={() => router.push(`/discounts/${droner?.username}`)}
        className="pointer relative rounded-br-normal rounded-all-normal flex align-items-end droner-card"
        sx={{ height: 330 }}
      >
        <Image
          src={
            searchRes
              ? droner?.image ?? "/assets/profile_placeholder.svg"
              : droner?.profImage ?? "/assets/profile_placeholder.svg"
          }
          alt={droner?.username ?? "drone pilot"}
          layout="fill"
          sizes="(max-width: 100%) 100%,
               100%"
          objectFit="cover"
          quality={50}
          className="rounded-all-normal"
        />
        <div className="flex col w-full h-full justify-content-end droner-card-popup">
          <Box
            sx={{ zIndex: 0, justifyContent: "space-between" }}
            className="flex w-full h-full col rounded-all-normal flex-column justify-content-between"
          >
            <Box className="flex justify-content-space-between">
              <div className="droner-card-popup-title">
                {droner?.displayName ? droner?.displayName : droner?.username}
              </div>
              <div className="droner-card-popup-btns">
                <Box onClick={(e) => e.stopPropagation()}>
                  <CompareSwitchButton label={false} droner={droner} />
                </Box>
                <div className="project-card-content__icon pointer">
                  <Link
                    href="mailto:"
                    style={{ paddingTop: "2px" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <ShareIcon color="#4D4E4E" />
                  </Link>
                </div>
                <div
                  className=""
                  onClick={() => {
                    router.push(`/droners/${droner?.username}`);
                  }}
                >
                  <div
                    className="project-card-content__icon pointer"
                    children={<ArrowIcon type="right" color="#1C1C1C" />}
                  />
                </div>
              </div>
            </Box>
            <div className="droner-card-popup-subtitle">
              {searchRes
                ? router.locale
                  ? droner?.address[router.locale]
                  : droner?.address["en"]
                : droner?.address}
            </div>
            <div className="droner-card-popup-description">
              <div className="droner-card-popup-text">
                Contact: <span>{droner.contactEmail}</span>
              </div>
              <div className="droner-card-popup-text">
                {/* NO DATA */}
                Licence: <span>Name of licence</span>
              </div>
              <div className="droner-card-popup-text">
                <span>{showEquipment(droner?.equipment)}</span>
              </div>
              <div className="droner-card-popup-block project-card-content__box">
                {/* NO DATA */}
                <h6>{5}</h6>
                <StarIcon />
              </div>
              <div className="d-flex justify-content-space-between flex-wrap">
                {droner?.skills?.map((s: any, i: number) => (
                  <div className="project-card-content__box" key={s}>
                    <h6>{s}</h6>
                  </div>
                ))}
              </div>
              <div
                className="droner-card-popup-profile"
                onClick={() => {
                  router.push(`/droners/${droner?.username}`);
                }}
              >
                <div>
                  <div className="droner-card-popup-profile-title">
                    PORTFOLIO
                  </div>
                  <div className="droner-card-popup-profile-arrow">
                    <RightBigArrowIcon />
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </div>
      </Box>
    </Card>
  );
}

export function DronerSmallCard({
  droner,
  handleClickAway,
}: {
  droner: any;
  handleClickAway: any;
}) {
  const { setSelectedDroner } = useContext(SelectedDroner);

  const clickAwayFunction = useCallback(
    (e: any) => {
      const card = document.getElementById("card");

      if (!e.target.closest("#card")) {
        handleClickAway();
      }
    },
    [handleClickAway]
  );

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener("click", clickAwayFunction);
    }, 200);
    return () => {
      document.removeEventListener("click", clickAwayFunction);
    };
  }, [clickAwayFunction]);

  const router = useRouter();

  return (
    <div id="card">
      <Box
        className="bg-surface flex gap-normal bg border-all-normal w-full overflow-hidden p-normal rounded-all-small"
        sx={{
          cursor: "pointer",
          maxWidth: "100%",
        }}
      >
        <Box
          className="relative overflow-all-hidden rounded-all-small overflow-hidden"
          sx={{
            minWidth: 60,
            width: 60,
            height: 60,
            cursor: "pointer",
          }}
          onClick={() => {
            setSelectedDroner(null);
            router.push(`/droners/${droner?.username}`);
          }}
        >
          <Image
            alt={droner?.username ?? "drone pilot"}
            src={droner?.profImage ?? "/assets/profile_placeholder.svg"}
            sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
            layout="fill"
            objectFit="cover"
            quality={50}
          />
        </Box>
        <Box mx={1} mb={1} sx={{ minWidth: 0 }}>
          <Link href={droner?.username ? `/droners/${droner.username}` : "/"}>
            <Typography
              noWrap
              sx={{
                overflow: "hidden",
                minWidth: 0,
                cursor: "pointer",
              }}
              className="link-hover"
            >
              {droner.displayName
                ? droner.displayName
                : droner?.username
                ? droner.username
                : dcContent.errors.noUserName}
            </Typography>
          </Link>
          <Box
            sx={{
              overflow: "hidden",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Typography variant="caption" noWrap>
              {droner?.address
                ? droner.address.city
                : dcContent.errors.noAddress}
            </Typography>
          </Box>
        </Box>
        <div style={{ flexGrow: 1 }} />
        <CompareSwitchButton label={false} droner={droner} />
      </Box>
    </div>
  );
}

export function DronerListCard({
  droner,
  searchRes = false,
}: {
  droner: any;
  searchRes?: boolean;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const router = useRouter();

  return (
    <Box
      className="no-highlights flex align-items-top gap-normal bg-surface border-all-normal w-full overflow-hidden p-normal rounded-all-small"
      sx={{
        cursor: "pointer",
        maxWidth: "100%",
      }}
    >
      <Box
        className="relative overflow-all-hidden rounded-all-small overflow-hidden"
        sx={{
          minWidth: 90,
          width: 90,
          height: 90,
          cursor: "pointer",
        }}
        onClick={() => {
          router.push(`/droners/${droner?.username}`);
        }}
      >
        <Image
          alt={droner?.username ?? "drone pilot"}
          src={droner?.profImage ?? "/assets/profile_placeholder.svg"}
          sizes="(min-width: 100vw) 100vw,
                  (min-width: 100vw) 100vw,
                 100vw"
          layout="fill"
          objectFit="cover"
          quality={50}
        />
      </Box>
      <Box
        className="px-small mb-small w-full flex-grow-1"
        sx={{ minWidth: 0 }}
      >
        <div className="overflow-hidden pointer flex row align-items-center w-full justify-content-space-between link-hover">
          <Link href={droner.username ? `/droners/${droner.username}` : "/"}>
            <Typography
              noWrap
              sx={{
                minWidth: 0,
              }}
            >
              {droner.displayName
                ? droner.displayName
                : droner.username
                ? droner.username
                : dcContent.errors.noUserName}
            </Typography>
          </Link>
          <div className="spacer" />
          <CompareSwitchButton label={false} droner={droner} />
        </div>
        <div
          style={{
            overflow: "hidden",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="caption" noWrap>
            {droner.address ? droner.address?.city : dcContent.errors.noAddress}
          </Typography>
        </div>
        <div>
          <ContactButton
            publicText={localeStrings.uiGeneral.email}
            hiddenText={droner.contactEmail}
            type="email"
            size="small"
            variant="text"
          />
        </div>
        <Box
          sx={{
            overflow: "hidden",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Typography variant="caption" noWrap>
            {droner?.skills?.map((skill: string, i: number) => {
              const locSkills = localeStrings.uiGeneral.skills;
              const locSkill = locSkills.filter((s) => s.skillId === skill)[0]
                ?.name;
              return (
                <span key={i}>
                  {locSkill}
                  {i + 1 < droner.skills.length && ","}{" "}
                </span>
              );
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
