import React, { useEffect, useState } from "react";

interface ArrowIconProps {
  type: "left" | "right" | "down" | "up";
  width?: string | number;
  height?: string | number;
  color?: string;
}

const ArrowIcon = ({ type, color, height, width }: ArrowIconProps) => {
  const [deg, setDeg] = useState(0);
  useEffect(() => {
    switch (type) {
      case "up":
        setDeg(180);
        break;
      case "left":
        setDeg(90);
        break;
      case "right":
        setDeg(270);
        break;
      default:
        setDeg(0);
        break;
    }
  }, [type]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${width ? width : 13}`}
      height={`${height ? height : 8}`}
      viewBox="0 0 13 8"
      fill="none"
      style={{ transform: `rotate(${deg}deg)` }}
    >
      <path
        d="M1.16602 1.33325L6.49935 6.66659L11.8327 1.33325"
        stroke={`${color ? color : "#2496CC"}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
