import React from "react";

const RightBigArrowIcon = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width || "26"}
      height={height || "27"}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 25.3574C16.0168 25.3574 18.9101 24.159 21.0433 22.0258C23.1766 19.8925 24.375 16.9993 24.375 13.9824C24.375 10.9656 23.1766 8.07231 21.0433 5.93908C18.9101 3.80586 16.0168 2.60742 13 2.60742C9.98316 2.60742 7.08989 3.80586 4.95666 5.93908C2.82343 8.07231 1.625 10.9656 1.625 13.9824C1.625 16.9993 2.82343 19.8925 4.95666 22.0258C7.08989 24.159 9.98316 25.3574 13 25.3574ZM13 0.982421C16.4478 0.982421 19.7544 2.35206 22.1924 4.79003C24.6304 7.22801 26 10.5346 26 13.9824C26 17.4302 24.6304 20.7368 22.1924 23.1748C19.7544 25.6128 16.4478 26.9824 13 26.9824C9.55219 26.9824 6.24558 25.6128 3.80761 23.1748C1.36964 20.7368 -4.17539e-07 17.4302 -5.68248e-07 13.9824C-7.18957e-07 10.5346 1.36964 7.22801 3.80761 4.79003C6.24558 2.35206 9.55218 0.982421 13 0.982421ZM7.3125 13.1699C7.09701 13.1699 6.89035 13.2555 6.73798 13.4079C6.5856 13.5603 6.5 13.7669 6.5 13.9824C6.5 14.1979 6.5856 14.4046 6.73798 14.5569C6.89035 14.7093 7.09701 14.7949 7.3125 14.7949L16.7261 14.7949L13.2372 18.2822C13.0847 18.4347 12.999 18.6417 12.999 18.8574C12.999 19.0732 13.0847 19.2801 13.2372 19.4327C13.3898 19.5852 13.5967 19.6709 13.8125 19.6709C14.0283 19.6709 14.2352 19.5852 14.3878 19.4327L19.2628 14.5577C19.3384 14.4822 19.3984 14.3925 19.4394 14.2938C19.4804 14.1951 19.5015 14.0893 19.5015 13.9824C19.5015 13.8755 19.4804 13.7697 19.4394 13.671C19.3984 13.5723 19.3384 13.4826 19.2628 13.4072L14.3877 8.53217C14.2352 8.37961 14.0283 8.2939 13.8125 8.2939C13.5967 8.2939 13.3898 8.37961 13.2372 8.53217C13.0847 8.68474 12.999 8.89166 12.999 9.10742C12.999 9.32318 13.0847 9.53011 13.2372 9.68267L16.7261 13.1699L7.3125 13.1699Z"
        fill="white"
      />
    </svg>
  );
};

export default RightBigArrowIcon;
