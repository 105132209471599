import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CompareItemsContext, LocalizationContext } from "src/lib/context";

export default function CompareSwitchButton({
  droner,
  label = true,
}: {
  droner: any;
  label?: boolean;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const { compareItems, setCompareItems } = useContext(CompareItemsContext);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);

  const maxNum = 4;

  function handleChecked() {
    if (!checked) {
      if (compareItems.length >= maxNum) {
        window.alert(`max ${maxNum} droner`);
      } else {
        setChecked(true);
        setCompareItems([...compareItems, droner.uid]);
      }
    } else {
      setChecked(false);
      setCompareItems(compareItems.filter((item) => item !== droner.uid));
    }
  }

  useEffect(() => {
    const result = compareItems?.find((uid) => uid === droner.uid);
    setChecked(!!result);
  }, [compareItems, droner.uid]);
  useEffect(() => {
    const result = compareItems?.find((uid) => uid === droner.uid);
    setChecked(!!result);
  }, [compareItems, droner.uid]);

  return (
    <div className="switch-btn">
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            onClick={() => handleChecked()}
            checked={checked}
            sx={{ ml: 1 }}
            inputProps={{ "aria-label": "compare" }}
          />
        }
        label={
          label && (
            <Typography
              variant="body2"
              sx={{
                display: {
                  xs: "none",
                  lg: "block",
                },
              }}
              className="link-hover"
            >
              {localeStrings?.uiGeneral?.compare}
            </Typography>
          )
        }
        disabled={disabled}
        labelPlacement="start"
      />
    </div>
  );
}
