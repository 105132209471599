import React from "react";

const ShareIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.86953 18.5043C1.42953 18.5043 1.053 18.3478 0.739931 18.0347C0.426331 17.7211 0.269531 17.3443 0.269531 16.9043V8.1043C0.269531 7.6643 0.426331 7.2875 0.739931 6.9739C1.053 6.66083 1.42953 6.5043 1.86953 6.5043H4.26953V8.1043H1.86953V16.9043H11.4695V8.1043H9.06953V6.5043H11.4695C11.9095 6.5043 12.2863 6.66083 12.5999 6.9739C12.913 7.2875 13.0695 7.6643 13.0695 8.1043V16.9043C13.0695 17.3443 12.913 17.7211 12.5999 18.0347C12.2863 18.3478 11.9095 18.5043 11.4695 18.5043H1.86953ZM5.86953 12.9043V3.9643L4.58953 5.2443L3.46953 4.1043L6.66953 0.904297L9.86953 4.1043L8.74953 5.2443L7.46953 3.9643V12.9043H5.86953Z"
        fill={color}
      />
    </svg>
  );
};

export default ShareIcon;
