import { useContext, useEffect, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ClickAwayListener, Link, Tooltip, Typography } from "@mui/material";
import { isMobileDevice } from "../../lib/global";
import { LocalizationContext } from "../../lib/context";

export default function ContactButton({
  size = "medium",
  publicText,
  hiddenText,
  type = "none",
  variant = "contained",
}: {
  size?: string;
  publicText: string;
  hiddenText: string;
  type?: string;
  variant?: string;
}) {
  const { localeStrings } = useContext(LocalizationContext);
  const [hidden, setHidden] = useState(true);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [actType, setActType] = useState<null | string>(null);

  useEffect(() => {
    setActType(
      type === "tel" || (type === "mailto" && isMobileDevice()) ? type : null
    );
  }, [type]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenToolTip(false);
      }}
    >
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={openToolTip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="top"
        title={localeStrings.uiGeneral.copied}
      >
        {hidden ? (
          <Typography
            onClick={() => setHidden(false)}
            variant="button"
            color="secondary"
            className="pointer"
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textTransform: "capitalize",
              fontWeight: 600,
            }}
          >
            {publicText}
          </Typography>
        ) : (
          <div
            onClick={() =>
              navigator.clipboard?.writeText(hiddenText).then(() => {
                setOpenToolTip(true);
              })
            }
            className="  flex row align-items-center justify-content-start gap-normal"
          >
            <Link
              href={`${actType}:${hiddenText}`}
              className=" pointer"
              noWrap
              color="primary"
              sx={{
                overflow: "hidden",
                minWidth: 0,
                fontWeight: 600,
              }}
            >
              {hiddenText}
            </Link>
            <ContentCopyIcon color="primary" sx={{ width: 14 }} />
          </div>
        )}
      </Tooltip>
    </ClickAwayListener>
  );
}
